// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-style-guide-src-template-js": () => import("./../../../node_modules/gatsby-theme-style-guide/src/template.js" /* webpackChunkName: "component---node-modules-gatsby-theme-style-guide-src-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oat-canada-roquette-increase-domestic-oat-ingredient-innovation-amid-surging-demand-for-plant-based-dairy-alternative-products-js": () => import("./../../../src/pages/oat-canada-roquette-increase-domestic-oat-ingredient-innovation-amid-surging-demand-for-plant-based-dairy-alternative-products.js" /* webpackChunkName: "component---src-pages-oat-canada-roquette-increase-domestic-oat-ingredient-innovation-amid-surging-demand-for-plant-based-dairy-alternative-products-js" */),
  "component---src-pages-stores-js": () => import("./../../../src/pages/stores.js" /* webpackChunkName: "component---src-pages-stores-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

